@import url("https://fonts.googleapis.com/css?family=Raleway:400");
*{
  margin :0;
  padding:0;
  box-sizing:border-box;
}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 75px;

}

body{
  font-weight :400;
  overflow-x: hidden;
  position:relative;
  background-color: #121212 !important;
  color:#fff !important;
  font-family: sans-serif !important;
}

h1,h2,h3,h4,h5,h6{
  margin:0;
  padding:0;
  line-height :normal;

}
p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/*nav css */

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
    font-size: 20px;
    text-shadow: 2px 2px 5px rgb(1, 136, 181);
    
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: white;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
/* .social-icon a::before {
    content: "";
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
} */
 
.social-icon a:hover {
    /* transform: scale(1);
     */
     width: 60px;
    height: 60px;
    
      box-shadow: 3px 3px 3px rgb(1, 136, 181);
    
    
}
/* .social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
} */
/* .social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
} */
 
.navbar-text button {
  /* background-color: white; */
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  /* background-color: #0c2a5f; */
  background: linear-gradient(90.21deg, #0f76ad -5.91%, #046751 111.58%);

  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #fdfbfb;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/* Banner css */

.name-css{
  color:#079178;
}
.banner {
  margin-top: 0;
  padding: 260px 0 300px 0;
  background-color: rgb(4, 4, 4);
  /* background-image: url('./assets/bg-img.avif'); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}







/* Skills css/ */



.skill {
  padding: 0 0 50px 0;
  position: relative;
  color:#12e7b8
}
/* .skill-bx {
  background: #060606;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  border: 4px solid rgb(28, 136, 126);
} */

@import url("https://fonts.googleapis.com/css?family=Raleway:400");

/* * {
  box-sizing: border-box;
} */

@property --angle {
  syntax: '<angle>';
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: '<percentage>';
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: true;
}



p {
  margin: 0;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: rgba(168, 239, 255, 1);
  --c2: rgba(168, 239, 255, 0.1);
}

.skill-bx {
  background: #060606;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  border: 4px solid rgb(28, 136, 126);
  border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
  animation: borderRotate var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}
.skill h5 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

.img-size{
height:130px;
width:150px;
/* border: 10px solid rgb(3, 3, 51); */
/* border-radius:50%; */
}
/* .img-size div:hover{
  box-shadow:10px 10px 5px rgb(90, 152, 172) ;
} */
 
.item {
  color: rgb(42, 163, 250);
  margin-left: 15px;
  margin-right: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  background-color: rgb(7, 7, 7); /* Initial background color */
  padding: 10px; /* Optional: Adds padding for better spacing */
  border-radius: px; /* Optional: Adds rounded corners */
  position: relative; /* Ensure relative positioning for pseudo-element */
}

.item::after {
  content: "";
  position: absolute;
  top: -5px; /* Adjust position to align with outer border */
  left: -5px; /* Adjust position to align with outer border */
  z-index: -1;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background: linear-gradient(
    60deg,
    hsl(258, 73%, 35%),
    hsl(146, 61%, 53%),
    hsl(175, 96%, 26%),
    hsl(157, 95%, 15%),
    hsl(168, 93%, 17%),
    hsl(174, 78%, 30%),
    hsl(174, 94%, 18%),
    hsl(179, 85%, 66%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: 20px; /* Match the outer border radius */
  animation: moveGradient 4s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.item:hover {
  color:white;
  transform: scale(1.05);
  box-shadow: 10px 10px 8px rgba(104, 148, 224, 0.2);
}

.item h2 {
  transition: text-shadow 0.3s ease;
}

.item:hover h2 {
  text-shadow: 10px 10px 5px rgba(3, 7, 82, 0.3);
}




/* project css */

.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #013e3f -5.91%, #034b49 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  height:300px;
  wdith:300px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #069851 -5.91%, #2fbdac 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.contact {
  margin-left: 20%;
  max-width: 1200px;
  border-radius: 50px;
  background-color: rgb(8, 8, 8);
  padding: 60px 20px 200px 20px;
  /* Add margin bottom to separate from other sections */
}

.contact img {
  width: 100%;
  height: auto;
  border-radius: 50px;
  animation: updown 3s linear infinite;
}

.contact h2 {
  color: #65dfdb;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center; /* Center align heading on all screen sizes */
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 20px 0;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input:hover,
.contact form textarea:hover {
  box-shadow: rgba(54, 232, 140, 0.35) 0px 5px 15px;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 12px 36px;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: linear-gradient(90.21deg, #04603b -5.91%, #034b49 111.58%);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

@media screen and (max-width: 992px) {
  .contact {
    margin-left: 5%;
    padding: 40px 10px 120px 10px;
  }

  .contact h2 {
    font-size: 30px;
  }

  .contact form button {
    padding: 12px 24px;
  }
}

@media screen and (max-width: 768px) {
  .contact {
    margin-left: 0;
    border-radius: 0;
    padding: 30px 10px 80px 10px;
  }

  .contact h2 {
    font-size: 24px;
  }

  .contact form input,
  .contact form textarea {
    padding: 14px;
    font-size: 14px;
  }

  .contact form button {
    padding: 10px 20px;
  }
}

/* footer css
 */
.footer {
  padding: 0 0 50px 0;
  background-color: #000;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer div {
  max-height: 15px;
  margin-left: 200px; /* Default margin */
}

@media screen and (max-width: 1200px) {
  .footer div {
    margin-left: 100px; /* Adjust margin for medium screens */
  }
}

@media screen and (max-width: 992px) {
  .footer div {
    margin-left: 50px; /* Adjust margin for tablets and small screens */
  }
}

@media screen and (max-width: 768px) {
  .footer {
    padding-bottom: 30px; /* Reduce padding on smaller screens */
  }
  
  .footer p {
    font-size: 12px; /* Decrease font size for smaller screens */
    margin-top: 10px; /* Adjust top margin */
  }
  
  .footer div {
    margin-left: 20px; /* Adjust margin for smaller screens */
  }
}







/* Resume css */
.content-box {
  background-color: black;
  color: white;
  padding: 20px;
  border: 2px solid #ffffff; /* White border */
}

.desc {
  text-align: center;
  margin-bottom: 20px;
}

.desc p {
  margin-bottom: 15px;
  border: 4px solid rgb(22, 113, 120);
}

.info-list {
  text-align: left;
  margin-bottom: 20px;
}

.info-list ul {
  list-style-type: none;
  padding: 0;
}

.info-list li {
  margin-bottom: 10px;
}

.icon {
  margin-right: 8px; /* Adjust spacing between icon and text */
}

.bts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative; /* To position the image container */
}

.btn {
  display: flex;
  align-items: center;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  padding: 0.8em 1.3em 0.8em 0.9em;
  color: rgb(202, 74, 209);
  background: #ad5389;
  background: linear-gradient(to right, #0e6489, #04704e, #24243e);
  border: none;
  letter-spacing: 0.05em;
  border-radius: 16px;
  text-decoration: none;
}

.btn svg {
  margin-right: 3px;
  transform: rotate(30deg);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.btn span {
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.btn:hover svg {
  transform: translateX(5px) rotate(90deg);
}

.btn:hover span {
  transform: translateX(7px);
}

.copied-msg {
  color: green;
  margin-top: 1rem;
}

.image-container {
  position: relative;
  display: inline-block; /* Ensures the container wraps around the image */
}

.about-image {
  display: block;
  width: 600px; /* Adjust as per your design */
  height: 600px; /* Maintain aspect ratio */
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for readability */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth opacity and transform transition */
}

.image-overlay h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.image-overlay p {
  font-size: 16px;
  line-height: 1.5;
}

.image-overlay:hover {
  opacity: 1; /* Show overlay on hover */
  transform: translateY(-10px); /* Shift text upwards slightly */
}

.additional-images {
  border-radius: 40px;
  display: flex;
  justify-content: space-between; /* Ensure images are evenly spaced */
  margin-top: 20px; /* Adjust as needed */
}

.additional-image-container {
  position: relative;
  width: 500px; /* Adjust width as needed */
  height: 500px; /* Maintain aspect ratio */
}

.additional-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.additional-image-container .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background for readability */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth opacity and transform transition */
}

.additional-image-container .image-overlay h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.additional-image-container:hover .image-overlay {
  opacity: 1; /* Show overlay on hover */
  transform: translateY(-10px); /* Shift text upwards slightly */
}

.clear {
  clear: both;
}

.copied-msg {
  color: white;
  font-size: 14px;
  opacity: 0.6; /* Low opacity */
  margin-left: 5px; /* Adjust margin as needed */
  vertical-align: middle; /* Align with button text */
}

.border-animation {
  position: relative;
}

.border-animation::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid transparent;
  box-sizing: border-box;
  pointer-events: none; /* Ensures the border overlay doesn't interfere with clicks */
  animation: border-animation 2.5s linear infinite;
}

@keyframes border-animation {
  0% {
    border-color: rgba(95, 6, 100, 0.3);
  }
  50% {
    border-color: rgba(128, 104, 237, 0.8);
  }
  100% {
    border-color: rgba(136, 7, 35, 0.3);
  }
}


/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/